import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useAddress } from '@thirdweb-dev/react';
import { getAmberToken } from '../../hooks/useAmberContractFunctions';
import {  useAmberContract,  useBattleContract,  useNftContract } from '../../hooks/initialContract';
import { useAppDispatch } from '../../store/store';
import { fetchStats } from '../../store/stats/statsSlice';
import { addAlert } from '../../store/alert/alertSlice';
import { AlertStatus } from '../../utils/alert.type';
import Link from 'next/link';

export default function AmberStats() {

  const [amberCoin, setAmberCoin] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const address = useAddress();
  const amber = useAmberContract();
  const battle = useBattleContract();
  const nft = useNftContract();

  useEffect(() => {
    async function fetchAmber() {
      try {
        if (address && amber) {
          const amberCoins = await getAmberToken(amber, address);
          setAmberCoin(amberCoins);
        }
      } catch (error) {
        dispatch(
          addAlert({
            title: 'Important notification',
            message: 'Something went wrong, please try again',
            type: AlertStatus.error
          })
        );
      }
    }

    fetchAmber();
  }, [address, amber]);

  useEffect(() => {
    const getStats = async () => {
      try {
        if (battle && address && amber && nft) {
          await dispatch(fetchStats({ battle, amber, nft, address }));
        }
      } catch (error) {
        dispatch(
          addAlert({
            title: 'Important notification',
            message: 'Something went wrong, please try again',
            type: AlertStatus.error
          })
        );
      }
    };

    getStats();
  }, [battle, address, amber, nft]);

  return (
    <>
      {amberCoin && address && (
      <Link href='/more/swap/' className='relative group min-w-[100px]'>
        <div className='bg-surfaceTop flex flex-row items-center p-[13px] gap-2 rounded-xl shadow-lg group-hover:bg-opacity-100 bg-opacity-70 cursor-pointer'>
          <Image
              src={'/icons/diamond.svg'}
              alt='Diamond icon'
              className='aspect-[1/1]'
              width={32}
              height={32}
              loading='lazy'
          />
          <div className='relative text-white text-[14px] 3xl:text-[20px] font-ubuntu font-[700] text-center'>
            {amberCoin} AMBER
          </div>
        </div>
      </Link>
      )}
    </>
  );
}
