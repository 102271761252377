import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

export default function Logo() {
    return (
      <div className='flex 2xl:flex-1 items-center justify-start'>
        <Link
            href='/'
            onClick={() => {
                window.scrollTo(0, 0);
            }}
        >
          <div className='flex items-center justify-center group gap-4 -ml-6'>
            <div className='relative w-[100px] flex items-center justify-center'>
              <Image
                className='ml-[2px] group-hover:drop-shadow-[0px_0px_5px_green]'
                src='/logo/christmasLogo.webp'
                width={55}
                height={55}
                alt='Logo Christmas Decision'
                priority
            />
            </div>
            <Image
                className='-ml-6 group-hover:drop-shadow-[0px_0px_5px_green]'
                src='/logo/WinterDesicionLogo.webp'
                style={{ width: '120px', height: 'auto' }}
                width={20}
                height={20}
                alt='Logo Decision'
                priority
            />
          </div>
        </Link>
      </div>
    );
}
