import { useContract } from '@thirdweb-dev/react'

export const useBattleContract = () => {
    const battle = useContract(process.env.NEXT_PUBLIC_BATTLE_TOKEN)
    return battle.contract
}

export const useAmberContract = () => {
    const amber = useContract(process.env.NEXT_PUBLIC_AMBER_TOKEN)
    return amber.contract
}

export const useNftContract = () => {
    const nft = useContract(process.env.NEXT_PUBLIC_NFT_TOKEN)
    return nft.contract
}

export const useNftGnomeContract = () => {
    const nftGnome = useContract(process.env.NEXT_PUBLIC_NFT_GNOME_TOKEN)
    return nftGnome.contract
}

export const useUniContract = () => {
    const uni = useContract(process.env.NEXT_PUBLIC_UNI_TOKEN)
    return uni.contract
}

export const useStakingContract = () => {
    const staking = useContract(process.env.NEXT_PUBLIC_STAKING_TOKEN)
    return staking.contract
}