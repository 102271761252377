export interface MenuItem {
  href: string;
  name: string;
  subMenu?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    href: '/',
    name: 'Home'
  },
  {
    href: '/game',
    name: 'Game'
  },
  {
    href: '/blog',
    name: 'Blog',
  },
  {
    href: '/about',
    name: 'About',
    subMenu: [
      {
        href: '/about#team',
        name: 'Team',
      },
      {
        href: '/about#partner',
        name: 'Partners',
      },
      {
        href: '/about#lore',
        name: 'Lore',
      },
      {
        href: '/about#how-to-play',
        name: 'How to play',
      },
      {
        href: '/about#contact',
        name: 'Contact',
      }
    ],
  },
  {
    href: '/wiki/cards',
    name: 'Wiki',
    subMenu: [
      {
        href: '/wiki/cards',
        name: 'Cards',
      },
      {
        href: '/wiki/tutorials',
        name: 'Tutorials',
      }
    ],
  },
  {
    href: '/more/mint',
    name: 'More',
    subMenu: [
      {
        href: '/more/mint',
        name: 'Mint',
      },
      {
        href: '/more/staking',
        name: 'Staking',
      },
      {
        href: '/more/swap',
        name: 'Swap',
      }
    ],
  }
];

export const footerMenu: MenuItem[] = [
  {
    href: '/',
    name: 'Home'
  },
  {
    href: '/game',
    name: 'Game'
  },
  {
    href: '/blog',
    name: 'Blog'
  },
  {
    href: '/about',
    name: 'About'
  },
  {
    href: '/privacy',
    name: 'Privacy'
  },
  {
    href: '/wiki/cards',
    name: 'Cards'
  },
  {
    href: '/wiki/tutorials',
    name: 'Tutorials'
  },
  {
    href: '/more/mint',
    name: 'Mint'
  },
  {
    href: '/more/staking',
    name: 'Staking'
  },
  {
    href: '/more/swap',
    name: 'Swap'
  }
];