export const getNftToken = async (nft: any, address: string) => {
  const mushroomsNft = await nft.call('balanceOf', [address]);
  return String(`${Number(mushroomsNft)}`)
};

export const setApprovalForAllMushrooms = async (
    nft: any,
    operator: string,
    approved: boolean
) => {
  return await nft.call('setApprovalForAll', [operator, approved]);
};